import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Logo from "../src/assets/logo.png";
import QuickLinks from "./QuickLinks";

const DeleteAccount = () => {
  const initialValues = {
    email: "",
    otp: "",
  };
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [otpVisible, setOtpVisible] = useState(false);
  const [timer, setTimer] = useState(30);
  const [resendVisible, setResendVisible] = useState(false);

  useEffect(() => {
    if (otpVisible && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setResendVisible(true);
    }
  }, [otpVisible, timer]);

  const generateOtp = async (email) => {
    try {
      setSubmitting(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/emailver_for_data_deletion`,
        { receiver_email: email }
      );
      console.log(response.data);
      toast.success("OTP sent successfully!", { theme: "dark" });
      setOtpVisible(true);
      setTimer(30);
      setResendVisible(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.error, {
        theme: "dark",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const verifyOtp = async (values) => {
    try {
      setSubmitting(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/user_data_deletion`,
        { otp: Number(values?.otp), email: values.email }
      );
      console.log(response.data);
      toast.success("OTP verified and account deleted successfully!", {
        theme: "dark",
      });
      navigate("/");
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.error, {
        theme: "dark",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div
        className="w-[50%] md:w-[16%] flex justify-start items-end gap-2 cursor-pointer mb-8 mt-8 mx-4"
        onClick={() => {
          window.history.back();
        }}
      >
        <img src={Logo} className="w-[50px] h-[50px] rounded-lg" alt="logo" />
        <h1 className="text-3xl font-bold text-white text-left">MYSYNR</h1>
      </div>
      <div className="max-w-lg md:mx-auto mx-6 h-[70vh]">
        <h1 className="text-2xl font-bold my-4 text-white">Delete Account</h1>
        <p className="text-sm text-red-600">
          Are you sure you want to delete your account? All data associated with
          this account will be permanently removed.
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email address")
              .required("Email is required"),
            otp: otpVisible && Yup.string().required("OTP is required"),
          })}
          onSubmit={(values) => {
            if (!otpVisible) {
              generateOtp(values.email);
            } else {
              verifyOtp(values);
            }
          }}
        >
          {({ touched, errors, isValid, handleSubmit, values }) => (
            <Form>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-white font-bold mt-6 mb-2"
                >
                  Email
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className={`border border-gray-300 rounded-md p-2 w-full text-white ${
                    touched.email && errors.email ? "border-red-500" : ""
                  }`}
                  placeholder="Enter email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              {otpVisible && (
                <div className="mb-4">
                  <label
                    htmlFor="otp"
                    className="block text-white font-bold mt-6 mb-2"
                  >
                    OTP
                  </label>
                  <Field
                    type="text"
                    id="otp"
                    name="otp"
                    className={`border border-gray-300 rounded-md p-2 w-full text-white ${
                      touched.otp && errors.otp ? "border-red-500" : ""
                    }`}
                    placeholder="Enter OTP"
                  />
                  <ErrorMessage
                    name="otp"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                  <div className="text-white mt-2">
                    {timer > 0 ? (
                      <p>Resend OTP in {timer}s</p>
                    ) : (
                      <p
                        onClick={() => generateOtp(values.email)}
                        className="cursor-pointer underline"
                      >
                        Resend OTP
                      </p>
                    )}
                  </div>
                </div>
              )}
              <button
                type="submit"
                className={`bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 ${
                  !isValid || submitting ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!isValid || submitting}
              >
                {submitting ? (
                  <div className="w-6 h-6 border-t-2 border-b-2 border-blue-500 rounded-full animate-spin"></div>
                ) : otpVisible ? (
                  "Verify OTP and Delete Account"
                ) : (
                  "Generate OTP"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="flex flex-col md:flex-row justify-around items-center mt-8">
        <QuickLinks />
        {/* Contact Us Section */}
        <div className="py-8">
          <div className="container mx-auto px-4 flex flex-col md:flex-row justify-around items-center gap-6">
            <h2 className="text-2xl font-bold text-white text-center mt-8 md:mt-0">
              Contact Us
            </h2>
            <div className="flex justify-center items-center">
              <p
                onClick={() => navigate("/email-us")}
                className="text-xl cursor-pointer text-gray-300 underline hover:text-white"
              >
                Email Us
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
