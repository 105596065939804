import React, { useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";

Modal.setAppElement("#root");

function Profile() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { id } = useParams();
  return (
    <div className="p-8">
      {/* <Modal
        isOpen={true}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Example Modal"
      > */}
      <p style={{ color: "#FFF" }}>Open this page in mysynr app</p>
      <p style={{ color: "#FFF" }} className="mt-4">
        <a
          style={{
            width: "200px",
            height: "60px",
            background: "#4db5ff",
            padding: "10px 20px",
            borderRadius: "10px",
            color: "white",
            cursor: "pointer",
            marginTop: "20px",
          }}
          href={`mysynr://profile/${id}`}
        >
          Open in App
        </a>
      </p>
      {/* </Modal> */}
    </div>
  );
}

export default Profile;
