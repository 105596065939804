import React, { useEffect } from "react";
import QuickLinks from "./QuickLinks";
import { useNavigate } from "react-router-dom";
import Logo from "../src/assets/logo.png";

const Privacy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="text-white p-8">
      <div
        className="  w-[50%] md:w-[16%] flex justify-start items-end gap-2 cursor-pointer mb-8"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={Logo} className="w-[50px] h-[50px] rounded-lg" alt="logo" />
        <h1 className="text-3xl font-bold text-white text-left ">MYSYNR</h1>
      </div>
      <p className="text-lg font-bold mb-4">Privacy Policy</p>
      <p>
        Mysynr.com LLC (“we”) are committed to protecting your privacy and
        ensuring the security of your personal information. This Privacy Policy
        outlines how we collect, use, and disclose your information when you use
        the Mysynr application. By using our application, you consent to the
        terms of this Privacy Policy.
      </p>
      <p className="text-lg font-bold mt-4">Information We Collect :</p>
      <p className="text-sm">
        We collect account information you provide. When you create an account
        or use our application, we may ask that you provide information such as
        your name, email address, profile picture, and other personal
        information by which you may be identified.
      </p>
      <p className="text-sm mt-2">
        We collect user-generated content. You may provide comments, messages,
        audio recordings, videos, text that you choose to create with or upload
        to the application and the associated metadata, such as when, where, and
        by whom the content was created.
      </p>
      <p className="text-sm mt-2">
        We collect information when you use the app. When you access and use the
        application, we may collect information about how you interact with our
        application, including your usage patterns, preferences, and device
        information, which includes photographs and audio files from the device.
      </p>
      <p className="text-sm mt-2">
        If you do not want us to collect this information do not download the
        application or delete it from your device.
      </p>

      <p className="text-lg font-bold mt-4">How We Use Your Information :</p>
      <p className="text-sm">
        We use the information we collect to provide, maintain, improve our
        application's functionality and user experience, and fulfill any other
        purpose for which you provide it. Your personal information may be used
        to communicate with you, respond to your inquiries, and provide customer
        support. The usage information we collect helps us to improve our
        application and to deliver a better and more personalized experience,
        including by storing information about your preferences and usage of the
        application. We may use aggregated and anonymized data for analytical
        purposes and to improve our services.
      </p>
      <p className="text-lg font-bold mt-4">Data Sharing and Disclosure :</p>
      <p className="text-sm">
        We do not sell, rent, or trade your personal information to third
        parties for marketing purposes. We may share your information with
        trusted third-party service providers who assist us in operating our
        application and delivering services to you, and to fulfil the purpose
        for which you provide it. These service providers are contractually
        obligated to protect your information and use it only for the purposes
        specified by us. We may disclose your information if required by any
        law, court order or legal process or if necessary or appropriate to
        protect our rights, property, or safety, or the rights, property, or
        safety of others.
      </p>
      <p className="text-lg font-bold mt-4">
        Your Collection Choices and Rights :
      </p>
      <p className="text-sm">
        You can review and change your personal information by logging into the
        application and visiting your account profile page.
      </p>
      <p className="text-sm mt-2">
        You may also send us an email at{" "}
        <a href="mailto:gerald@mysynr.com" className="text-blue-500">
          gerald@mysynr.com
        </a>{" "}
        to request access to, correct, or delete any personal information that
        you have provided to us. We cannot delete your personal information
        except by also deleting your user account. We may not accommodate a
        request to change information if we believe the change would violate any
        law or legal requirement or cause the information to be incorrect.
      </p>
      <p className="text-lg font-bold mt-4">Data Security :</p>
      <p className="text-sm">
        We implement security measures to protect your personal information
        against unauthorized access, alteration, disclosure, or destruction. All
        information you provide to us is stored on our secure servers which may
        include secure cloud storage solutions. Despite our efforts to secure
        your information, no method of transmission over the internet or
        electronic storage is 100% secure. Therefore, we cannot guarantee
        absolute security. Any transmission of personal information is at your
        own risk. We are not responsible for circumvention of any privacy
        settings or security measures we provide.
      </p>
      <p className="text-lg font-bold mt-4">Children's Privacy :</p>
      <p className="text-sm">
        Our application is intended for use by children under 18 only under
        adult supervision. Accounts for children under 18 can be created using
        our parent control feature, allowing parents to manage their child's
        activity within the application. We do not knowingly collect personal
        information from children under 18 without parental consent. If you
        believe that we have inadvertently collected personal information from a
        child under 18 without appropriate consent, please contact us
        immediately at {" "}
        <a href="mailto:gerald@mysynr.com" className="text-blue-500">
          gerald@mysynr.com
        </a>{" "}
        .
      </p>
      <p className="text-lg font-bold mt-4">Nudity and Pornography :</p>
      <p className="text-sm">
        We strictly prohibit the creation, upload, or sharing of content that
        contains nudity or pornography. Violation of this policy may result in
        the immediate suspension or termination of your account.
      </p>
      <p className="text-lg font-bold mt-4">Face Data Policy :</p>
      <p className="text-sm">
        Our app collects basic facial landmarks, such as eye positions, nose,
        and mouth, using AWS Rekognition to estimate the user's age. This face
        data is used solely for age detection to enforce our policy that users
        under 18 cannot create an account without parental oversight. The face
        data is processed exclusively by AWS Rekognition, adhering to stringent
        privacy and security standards, and is securely stored in an Amazon S3
        bucket with encrypted transmission. The data is not shared with any
        third parties beyond AWS, and it is retained for up to 30 days, after
        which it is automatically and permanently deleted from our systems to
        ensure privacy and security.
      </p>
      <p className="text-lg font-bold mt-4">Changes to Privacy Policy :</p>
      <p className="text-sm">
        We reserve the right to update or modify this Privacy Policy at any time
        without prior notice. Any changes will be effective immediately upon
        posting the updated Privacy Policy on our website. If we make material
        changes to how we treat our users' personal information, we will notify
        you through a notice on the website home page or as required by
        applicable law. It is your responsibility to review this Privacy Policy
        periodically. Your continued use of our application after any changes
        indicates your acceptance of the updated Privacy Policy.
      </p>
      <p className="text-lg font-bold mt-4">Contact Us :</p>
      <p className="text-sm">
        If you have any questions or concerns about this Privacy Policy or our
        privacy practices, please contact us at{" "}
        <a href="mailto:gerald@mysynr.com" className="text-blue-500">
          gerald@mysynr.com
        </a>{" "}
        .
      </p>
      <p className="text-sm mt-4">
        Thank you for trusting us with your personal information.
      </p>
      <div className="flex flex-col md:flex-row justify-around items-center mt-8">
        <QuickLinks />
        {/* Contact Us Section */}
        <div className="py-8">
          <div className="container mx-auto px-4 flex flex-col md:flex-row justify-around items-center gap-6">
            <h2 className="text-2xl font-bold text-white  text-center mt-8 md:mt-0">
              Contact Us
            </h2>
            <div className="flex justify-center items-center">
              <p
                onClick={() => navigate("/email-us")}
                className="text-xl cursor-pointer text-gray-300 underline hover:text-white"
              >
                Email Us
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
